<template>
  <nav
    class="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg p-0"
    color-on-scroll="100"
    id="sectionsNav"
  >
    <div class="container-fluid">
      <div class="navbar-translate">
        <router-link to="/">
          <img
            src="../../public/assets/img/atv_logo.png"
            class="img-fluid ml-sm-4"
            style="width: 70px"
            alt
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/home" v-if="userInfo"
              >Home</router-link
            >
            <router-link class="nav-link" to="/" v-else>Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/movies">Movies</router-link>
          </li>
          <li class="dropdown nav-item">
            <a
              href="#"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="material-icons"></i> TV
              <div class="ripple-container"></div
            ></a>
            <div class="dropdown-menu dropdown-with-icons">
              <router-link to="/live-tv" class="dropdown-item text-dark">
                <i class="material-icons">tv</i> Live TV
              </router-link>
              <router-link to="/series" class="dropdown-item text-dark">
                <i class="material-icons">movie</i> Series List
              </router-link>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/magazine">Magazine</router-link>
          </li>
          <!--<li class="nav-item">-->
          <!--<router-link class="nav-link" to="/store">Store</router-link>-->
          <!--</li>-->
          <li class="nav-item">
            <a href="https://music.aukiss.tv/" target="_blank" class="nav-link">
              Audio
            </a>
            <!--<router-link class="nav-link" to="/radiomain"> Radio</router-link>-->
          </li>
           <!--<li class="nav-item">
            <router-link class="nav-link" to="/audio"> Audio</router-link>
          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" to="/help">Help</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto pt-2">
          <li
            class="dropdown nav-item profile_nav"
            v-if="$store.state.userInfo"
          >
            <a
              href="#"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                v-lazy="
                  $store.state.userInfo.photo
                    ? $store.state.userInfo.photo
                    : '../../../assets/img/blank_profile.png'
                "
                alt=""
                style="height: 30px"
                class="rounded-circle img fluid"
              />
              <span class="text-capitalize"
                >&nbsp;{{ ($store.state.userInfo || {}).first_name }}</span
              >
              <div class="ripple-container"></div>
            </a>
            <div class="dropdown-menu dropdown-with-icons">
              <router-link to="/account" class="dropdown-item text-dark">
                Profile
              </router-link>
              <router-link to="/logout" class="dropdown-item text-dark">
                Logout
              </router-link>
            </div>
          </li>
          <li class="p-2" v-if="!$store.state.userInfo">
            <router-link class="nav-link btn btn-outline-atvyellow" to="/login"
              >Login</router-link
            >
          </li>
          <li class="p-2" v-if="!$store.state.userInfo">
            <router-link class="nav-link btn btn-atvyellow" to="/register"
              >Register</router-link
            >
          </li>
          <li class="p-2" v-if="!$store.state.userInfo">
            <router-link class="nav-link btn btn-atvyellow" to="/content_signup"
              >Content creator </router-link
            >
          </li>
          <li class="p-2" v-if="!$store.state.userInfo">
            <button
              type="button"
              @click="handleGoogleLogin()"
              class="nav-link btn btn-outline-danger"
            >
              Google
            </button>
          </li>
          <!-- <li class="p-2" v-if="!$store.state.userInfo">
            <button
              type="button"
              @click="handleFacebookLogin()"
              class="nav-link btn btn-outline-facebook"
            >
              Facebook
            </button>
          </li> -->

          <!-- <img
            v-if="!$store.state.userInfo"
            src="../../public/assets/img/google.png"
            alt=""
            class="img-fluid mr-3"
            ype="button"
            @click="handleGoogleLogin()"
          /> -->

          <!-- <img
            v-if="!$store.state.userInfo"
            src="../../public/assets/img/facebook.png"
            alt=""
            class="img-fluid mr-3"
            ype="button"
            @click="handleFacebookLogin()"
          /> -->

          <li class="pl-5"></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase";
import Swal from "sweetalert2";
import { db } from "@/firebaseConfig";

export default {
  name: "navigation",
  data() {
    return {
      userInfo: this.$store.state.userInfo,

      data: {
        user_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
        device_token: "",
        address: "",
        gender: ""
      }
    };
  },

  created() {
    this.getMyToken();
  },

  methods: {
    getMyToken() {
      if (db.firemessaging) {
        const messaging = db.firemessaging;
        messaging
          .getToken({
            vapidKey:
              "BGEfhqbz5V-0-ZUZkxP8Z8vzMKXEmg1ygefLM_nBhcEjJtGYBeFKiaW3Og7cghxAJyDEhxtbIb87LLDDTJ-lK-A"
          })
          .then(async token => {
            if (token) {
              this.data.device_token = token;
            } else {
              this.notificationsPermisionRequest();
            }
          })
          .catch(function(err) {
            // console.log('An error occurred while retrieving token. ', err);
          });
      }
    },
    notificationsPermisionRequest() {
      const messaging = db.firemessaging;
      messaging
        .requestPermission()
        .then(() => {
          this.getToken();
        })
        .catch(err => {
          console.log("Unable to get permission to notify.", err);
        });
    },

    handleGoogleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      // console.log(provider);
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          //console.log("result", result);
          //console.log("result", result.credential);
          //console.log("user", result.user);

          this.data.email = result.user.email;
          this.data.first_name = result.additionalUserInfo.profile.given_name;
          this.data.user_name = result.additionalUserInfo.profile.given_name;
          this.data.last_name = result.additionalUserInfo.profile.family_name;
          this.data.phone_number = result.user.phoneNumber;
          this.data.password = result.additionalUserInfo.profile.given_name;
          this.data.password_confirmation =
            result.additionalUserInfo.profile.given_name;
          this.data.referral_code = "";

          //console.log("data", this.data);

          this.$store
            .dispatch("retrieveGFToken", this.data)
            .then(res => {
              this.$router.push('/home');
              // console.log('data', res);
              // this.$router.push("/referral-code");
            })
            .catch(err => {
              console.log("console error", err);
              Swal.fire({
                title: "Error!",
                html: (err.data.error || {}).email
                  ? (err.data.error || {}).email[0] + "<br>"
                  : "" + (err.data.error || {}).phone_number
                  ? (err.data.error || {}).phone_number[0]
                  : "",
                icon: "error",
                confirmButtonText: "Close"
              });
            })
            .finally(() => {});
        })
        .catch(err => {
          console.log("err", err);
        });
    },

    handleFacebookLogin() {
      const provider = new firebase.auth.FacebookAuthProvider();
      console.log(provider);

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          //console.log("result", result);
          //console.log("result", result.credential);
          //console.log("user", result.user);

          this.data.email = result.user.email;
          this.data.first_name = result.additionalUserInfo.profile.first_name;
          this.data.user_name = result.additionalUserInfo.profile.first_name;
          this.data.last_name = result.additionalUserInfo.profile.last_name;
          this.data.phone_number = result.user.phoneNumber;
          this.data.password = result.additionalUserInfo.profile.first_name;
          this.data.password_confirmation =
            result.additionalUserInfo.profile.first_name;
          this.data.referral_code = "";

          this.$store
            .dispatch("retrieveGFToken", this.data)
            .then(res => {
              this.$router.push('/home');
              // this.$router.push("/referral-code");
            })
            .catch(err => {
              Swal.fire({
                title: "Error!",
                html: (err.data.error || {}).email
                  ? (err.data.error || {}).email[0] + "<br>"
                  : "" + (err.data.error || {}).phone_number
                  ? (err.data.error || {}).phone_number[0]
                  : "",
                icon: "error",
                confirmButtonText: "Close"
              });
            })
            .finally(() => {});
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }
};
</script>

<style scoped>
/* navbar */
.navbar.navbar-transparent {
  background: #00000036 !important;
}

.nav-item a {
  color: white;
  font-size: 17px !important;
  text-transform: none !important;
}

.navbar-transparent .nav-item a {
  color: white;
}

.navbar {
  box-shadow: none !important;
  background: #1c212e !important;
}

@media (max-width: 991px) {
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: white;
  }

  .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: black !important;
    margin: 5px 15px;
  }
}
.dropdown-item:hover .material-icons {
  color: white !important;
}

.router-link-exact-active {
  color: #a88d30 !important;
}
</style>
